import BlogListLayout from "templates/blog-list"
import LocalizedLink from "components/localized-link"
import PropTypes from "prop-types"
import React from "react"
import SEO from "components/seo"
import { graphql } from "gatsby"
import useTranslations from "components/use-translations"

export default function Blog({ data }) {
  const {
    blog: { seoTitle, seoDescription, devaminiOku },
  } = useTranslations()
  return (
    <BlogListLayout>
      <SEO
        title={seoTitle}
        description={seoDescription}
        pathname="https://www.kodvizit.com/blog"
      />
      {data.allMdx.nodes.map(node => (
        <div className="mb-4 border-bottom" key={node.frontmatter.slug}>
          <span className="text-muted d-block mb-2" style={{ fontSize: 15 }}>
            {node.frontmatter.date}{" "}
          </span>
          <h4 className="font-weight-bold mb-3">
            <LocalizedLink
              to={node.frontmatter.slug}
              className="text-decoration-none"
              style={{ width: 150 }}
            >
              {node.frontmatter.title}
            </LocalizedLink>
          </h4>
          <div className="mb-5">
            {node.excerpt}
            <LocalizedLink
              to={node.frontmatter.slug}
              role="button"
              className="d-block btn btn-sm btn-outline-info mt-4 text-dark"
              style={{ width: 150 }}
            >
              {devaminiOku} &#8594;
            </LocalizedLink>
          </div>
        </div>
      ))}
    </BlogListLayout>
  )
}

export const query = graphql`
  query BLOG_INDEX_QUERY($locale: String!, $dateFormat: String!) {
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: {
        frontmatter: { type: { eq: "blog" } }
        fields: { locale: { eq: $locale } }
      }
    ) {
      nodes {
        id
        excerpt(pruneLength: 250)
        frontmatter {
          title
          slug
          date(formatString: $dateFormat)
        }
      }
    }
  }
`
Blog.propTypes = {
  data: PropTypes.object,
}

import Container from "react-bootstrap/Container"
import Layout from "components/layout"
import PropTypes from "prop-types"
import React from "react"
import useTranslations from "components/use-translations"

export default function BlogList({ children }) {
  const {
    genel: { blog },
  } = useTranslations()
  return (
    <Layout>
      <Container>
        <h2 className="my-4 ">{blog}</h2>
      </Container>
      <div className="border-bottom mb-3" />
      <Container style={{ minHeight: 400 }}>{children}</Container>
    </Layout>
  )
}

BlogList.propTypes = {
  children: PropTypes.node,
}
